<template>
<main id="main" class="site-main site-main-detail">
    <div class="postercard">
        <div class="postercard__img">
            <img src="../assets/uploads/featured-image.jpg" alt="Featured Image">
            <!-- <img :src="urlPath+'uploads/media/package/category/'+categorydet.image" :srcset="urlPath+'uploads/media/package/category/'+categorydet.image+'  1400w'" alt="image description"> -->
        </div>
    </div>
    <div class="breadcrumb">
        <div class="container">
            <ul>
                <li><router-link :to="{name:'Home'}">Home</router-link></li>
                <li><span class="live">Featured Package</span></li>
            </ul>
        </div>
    </div>

    <section class="bundle__box" v-if="categorypackage">
        <div class="container">
            <div class="row">

                <div class="col xs-12 sm-4 md-3" v-for="(catpackage,index) in categorypackage" :key="index">
                    <article class="bundle">
                        <div class="bundle__img">
                            <router-link :to="{name:'Packagedetail', params:{slug:catpackage.slug,title:catpackage.seo_title,keyword:catpackage.seo_keyword,description:catpackage.seo_description}}"><img :src="urlPath+'uploads/media/package/thumbnail/326245_'+catpackage.featuredimage" alt="image description"></router-link>
                            <span class="bundle__duration"><strong>{{catpackage.tripdays}}</strong> Days</span>
                        </div>
                        <div class="bundle__desc">
                            <h3 class="bundle__desc-title">
                                <router-link :to="{name:'Packagedetail', params:{slug:catpackage.slug,title:catpackage.title}}">{{catpackage.title}}</router-link>
                            </h3>
                            <p v-if="catpackage.excerpt">{{truncateText(catpackage.excerpt)}}</p>
                            <!-- <span class="bundle__desc-rating rating rating-{{catpackage.packagerating}}"><span class="text">{{catpackage.packagerating}}</span></span> -->
                            <span class="bundle__desc-price" v-if="catpackage.discountedprice">US {{catpackage.discountedprice}}</span>
                            <span class="bundle__desc-price" v-else>US {{catpackage.regularprice}}</span>
                        </div>
                    </article>
                </div>

            </div>
        </div>
    </section>
</main>
</template>

<script>
import axios from 'axios';
import jQuery from 'jquery';
import "../assets/js/jquery.matchHeight.js";
export default {
    name: 'Featuredcategory',
    data() {
        return {
            categorypackage: undefined,
            apiPath: process.env.VUE_APP_API,
            urlPath:process.env.VUE_APP_PATH,
        }
    },
    async mounted() {
        this.getCategorypackage(this.$route.params.slug);

    },
    methods: {
        getCategorypackage(slugurl) {
            axios.get(this.apiPath + 'featuredpackageall')
                .then(packageresponse => {
                    this.categorypackage = packageresponse.data;
                    const descEl = document.querySelector('head meta[name="description"]');
                    const keyEl = document.querySelector('head meta[name="keywords"]');
                    const titleEl = document.querySelector('head title');

                    descEl.setAttribute('content', 'Nepal Alternative Treks Featured Packages');
                    keyEl.setAttribute('content', 'featured, Nepal Alternative Treks, Trekking, Climbing, Tour, Hiking');
                    titleEl.textContent = "Featured Packages :: Nepal Alternative Treks";
                })
                .catch(error => {
                    console.log(error.response)
                });
        },
        truncateText(value, length = 80) {
            return value.length <= length ? value : value.split(' ').slice(0, 12).join(" ") + "..."
        }
    },
    watch: {
        '$route.params.slug'(newSlug) {
            this.getCategorypackage(newSlug);
        }
    },
    async updated(){
        jQuery('.bundle__desc-title').matchHeight();
        jQuery('.bundle__desc p').matchHeight();
    }

}
</script>
